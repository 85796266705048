import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import Text from "../components/global/text";
import Projects from "../components/global/projects";
import Develop from "../components/global/develop";
import HeaderGlobal from "../components/headers/header-global";
import { graphql } from "gatsby";
import Offer from "../components/global/offer";
import Server from "../assets/images/other-services/server.svg";
import Quality from "../assets/images/other-services/quality.svg";
import Network from "../assets/images/other-services/network.svg";
import Strategy from "../assets/images/other-services/strategy.svg";
import WeBring from "../components/other-services/we-bring";
import AppDevBcg from "../assets/images/headers/other-services.png"

// Text Component





const OtherServices = () => {
  const { t } = useTranslation()
  // Header Content
  const hTitle = `${t('services.otherServices.headline')}`;
  const hTextOne = `${t('services.otherServices.firstText')}`;

  const textContent = {
    title: `${t('services.otherServices.offerings.title')}`,
    text: `${t('services.otherServices.offerings.text')}`,
  };
  // Offer Component
  const offerContent = [
    {
      icon: <Server />,
      title: `${t('services.otherServices.offerContent.first.title')}`,
      text: `${t('services.otherServices.offerContent.first.text')}`,
      btnHref: "#",
    },
    {
      icon: <Quality />,
      title: `${t('services.otherServices.offerContent.second.title')}`,
      text: `${t('services.otherServices.offerContent.second.text')}`,
      btnHref: "#",
    },
    {
      icon: <Network />,
      title: `${t('services.otherServices.offerContent.third.title')}`,
      text: `${t('services.otherServices.offerContent.third.text')}`,
      btnHref: "#",
    },
    ,
    {
      icon: <Strategy />,
      title: `${t('services.otherServices.offerContent.fourth.title')}`,
      text: `${t('services.otherServices.offerContent.fourth.text')}`,
      btnHref: "#",
    },
  ];
  return (
    <Layout>
      <Seo title={t('navigation.services.otherServices')} />
      <HeaderGlobal
        hTitle={hTitle}
        hTextOne={hTextOne}
        image={AppDevBcg}
      />
      <WeBring />
      <Text
        textContent={textContent}
        rowWidth="_l12"
        backgroundColor="-redBorder"
        cssModifer='-services -whiteBg'

      />
      <Offer offerContent={offerContent} />
      <Develop />
    </Layout>
  );
};

export default OtherServices;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`